import React, { useState, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import {
  assignTechnisian,
  editOrderDetails,
  sendInvoiceDetails,
  getOrderStatus,
  getTechnician,
  getOrderDetails,
} from "common/Services/dbServices"
import Loader from "components/Common/Loader"
import {
  Input,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import moment from "moment"
import { useNavigate } from "react-router-dom"

const FormElements = () => {
  const location = useLocation()
  const data = location.state
  console.log("data ==",data)
  const { editId } = useParams()
  const [orderValues, setOrderValues] = useState([])
  const [technician, setTechnician] = useState([])
  const [selectOrderStatus, setSelectOrderStatus] = useState(data.orderStatus)
  const [selectTechnician, setSelectTechnician] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  useEffect(() => {
    getOrderStat()
    getTechnicianList()
    getAssignedTech()
  }, [])

  const getAssignedTech = async () => {
    const res = await getOrderDetails({ editId })
    setSelectTechnician(`${res.assignedTechnicianid}`)
  }
  const getOrderStat = async () => {
    const result = await getOrderStatus()
    setOrderValues([result])
  }
  const getTechnicianList = async () => {
    const result = await getTechnician()

    setTechnician(result)
  }

  const onSubmit = async e => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const result = await editOrderDetails({
        editId,
        selectOrderStatus,
        selectTechnician,
      })
      if (selectOrderStatus === "completed" && result) {
        try {
          const results = await sendInvoiceDetails({
            editId,
          })
        } catch (error) {
          alert("Failed invoice")
        }
      }
      alert("Item Updated Successfully")
      setIsLoading(false)
      navigate("/orders")
    } catch (error) {
      console.log("Error=", error)
      alert("Failed to Updated")
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const sendInvoice = async editId => {
    console.log("editId", editId)
    try {
      const results = await sendInvoiceDetails({
        editId,
      })
      alert("Invoice send successfully")
    } catch (error) {
      alert("Failed invoice")
    }
  }

  const orderPlaceDate = moment(data.orderPlacedOn).format("LLL")
  console.log("ser add==", data.additionalService)

  console.log("data.service=", data)

  document.title = "Order Details | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <Breadcrumbs
                maintitle="Sayartak"
                title="Orders"
                breadcrumbItem="Order Details"
                url="/Orders"
              />
            </Col>
            <Col sm={5}>
              <div className="float-end d-none d-md-block">
                <Button
                  color="primary"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                  onClick={onSubmit}
                >
                  Update
                </Button>
              </div>
              <div className="float-end d-none d-md-block me-2">
                <Button
                  color="primary"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                  onClick={() =>
                    navigate(`/chat/${editId}`, {
                      state: data,
                    })
                  }
                >
                  Chat
                </Button>
              </div>
              {selectOrderStatus === "completed" ? (
                <div className="float-end d-none d-md-block me-2">
                  <Button
                    color="primary"
                    className="btn btn-primary"
                    style={{ marginTop: 10 }}
                    onClick={() => sendInvoice(editId)}
                  >
                    Send Invoice
                  </Button>
                </div>
              ) : null}
            </Col>
          </Row>
          {isLoading ? (
            <div
              style={{
                position: "fixed",
                top: "40%",
                left: "55%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          ) : (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div className="row">
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Customer 
                          </label>
                          <div class="details-section-item-content">
                            <span>{data?.getCustomer?.name || "-"}</span> ,
                            <span>Mobile Number: {data?.getCustomer?.mobileNumber || "-"}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Order PlaceOn
                          </label>
                          <div class="details-section-item-content">
                            <span>{orderPlaceDate || "-"}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-12">
                        <hr style={{ width: "100%" }} />
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Vehicle
                          </label>
                          <div class="details-section-item-content">
                            <span>{data?.vehicle?.name.en || "-"}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Address
                          </label>
                          <div class="details-section-item-content">
                            <span>{data?.address?.name || "-"}</span>
                            <span>{data?.address?.streetAddress || "-"}</span>
                            <span>
                              BuildingNumber :
                              {data?.address?.buildingNumber || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-12">
                        <hr style={{ width: "100%" }} />
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Service
                          </label>
                          <div class="details-section-item-content">
                            {data.service.map((item, index) => {
                              return (
                                <div key={index} style={{ display: "flex",justifyContent:"space-between", width:'50%' }}>
                                  <span>{item?.name?.en}</span>
                                  <span>{"  "+"Qty : "+item?.quantity || "-"}</span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Rated
                          </label>
                          <div class="details-section-item-content">
                             <span>{data?.isRated ? 'True' : 'False' || "-"}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-12">
                        <hr style={{ width: "100%" }} />
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">Slot</label>
                          <div class="details-section-item-content">
                            <span>{data?.slot?.slot?.name || "-"}</span>
                            {/* <span>
                 {" "}
                 ({moment(data?.slot.date).format("Do MMMM YYYY")})
               </span> */}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Slot Date
                          </label>
                          <div class="details-section-item-content">
                            <span>
                              {" "}
                              {moment(data?.slot.date).format("Do MMMM YYYY") ||
                                "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-12">
                        <hr style={{ width: "100%" }} />
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            PaymentStatus
                          </label>
                          <div class="details-section-item-content">
                            <span>{data?.paymentStatus || "-"}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            PaymentMethod
                          </label>
                          <div class="details-section-item-content">
                            <span>
                              {data?.payment[0]?.paymentMethod || "-"}
                            </span>{" "}
                            ({data.payment[0].status || "-"})
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-12">
                        <hr style={{ width: "100%" }} />
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Order Ref
                          </label>
                          <div class="details-section-item-content">
                            <span>{data?.refNumber || "-"}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Coupon Code
                          </label>
                          <div class="details-section-item-content">
                            {data && data.coupon && data.coupon.couponCode ? (
                              <span>{data?.coupon.couponCode || "-"}</span>
                            ) : (
                              "Empty"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-12">
                        <hr style={{ width: "100%" }} />
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Order Status
                          </label>
                          <div class="details-section-item-content">
                            <select
                              className="form-control"
                              value={selectOrderStatus}
                              onChange={e =>
                                setSelectOrderStatus(e.target.value)
                              }
                            >
                              {orderValues.map(values => {
                                return (
                                  <>
                                    <option value="placed">
                                      {values?.placed.en}
                                    </option>
                                    <option value="assigned">
                                      {values?.assigned?.en}
                                    </option>
                                    <option value="inProgress">
                                      {values?.inProgress?.en}
                                    </option>
                                    <option value="completed">
                                      {values?.completed?.en}
                                    </option>
                                    <option value="refunded">
                                      {values?.refunded?.en}
                                    </option>
                                    <option value="onWay">
                                      {values?.onWay?.en}
                                    </option>
                                  </>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="details-section-item">
                          <label class="details-section-item-label">
                            Technician
                          </label>
                          <div class="details-section-item-content">
                            <select
                              className="form-control"
                              value={selectTechnician}
                              onChange={e =>
                                setSelectTechnician(e.target.value)
                              }
                            >
                              {technician.map(values => {
                                // console.log("tec::",values)
                                return (
                                  <option value={values?._id}>
                                    {values?.name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="table-responsive mt-3">
                    <table class="table table-hover">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">Service</th>
                            <th scope="col">Quantity</th>
                          </tr>
                        </thead>

                        <tbody>
                          {isLoading ? (
                            <tr>
                              <td colSpan="8">
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            data.service?.map((item, index) => {
                              return (
                                <tr>
                                  <td>
                                    <span>{item.name.en}</span>
                                  </td>
                                  <td>
                                    <span>{item.quantity}</span>
                                  </td>
                                </tr>
                              )
                            })
                          )}
                        </tbody>
                      </table>
                    </div> */}
                    {/* <Row className="mb-3">
         <label className="col-md-2 col-form-label">Technician </label>:
         <div className="col-md-6">
         <select className="form-control" value={selectTechnician} onChange={e => setSelectTechnician(e.target.value)}>
             {technician.map((values) => {
               // console.log("tec::",values._id)
               return (

                   <option value={values._id}>{values?.userName}</option>
               )
             })}
           </select>

         </div>
       </Row> */}
                    {/* <Row className="mb-3">
         <label className="col-md-2 col-form-label">Technician </label>:
         <div className="col-md-6">
           <select className="form-control" value={selectTechnician} onChange={e => setSelectTechnician(e.target.value)}>
             {technician.map((values) => {
               // console.log("tec::",values._id)
               return (

                   <option value={values._id}>{values?.userName}</option>
               )
             })}
           </select>

         </div>
       </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
